import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppoinmentService } from '../../services/appoinment/appoinment.service';
import { CustomerService } from '../../services/customer/customer.service';
import { ToastComponent } from '../../shared/toast/toast.component';
import { finalize } from 'rxjs';
import {
  AvailableTimesModel,
  GroupedTimesModel,
} from '../../models/appointment/appoinment.model';

@Component({
  selector: 'app-create-appoinment',
  standalone: true,
  imports: [
    CommonModule,
    NgbDatepickerModule,
    FormsModule,
    RouterModule,
    ToastComponent,
  ],
  templateUrl: './create-appoinment.component.html',
  styleUrl: './create-appoinment.component.scss',
})
export class CreateAppoinmentComponent {
  @ViewChild(ToastComponent) toastComponent!: ToastComponent;
  model!: NgbDateStruct;
  today: NgbDateStruct;
  showToast: boolean = false;
  toastMessage: string = '';
  toastType: 'success' | 'error' | 'warning' = 'success';
  appoinmentTypes: { id: string; name: string }[] = [];
  resources: { id: string; name: string }[] = [];
  availableTimes: AvailableTimesModel[] = [];
  selectedAppoinmentType: string | null = null;
  selectedResource: string | null = '';
  selectedDate: string | null = null;
  displaySelectedDate: string | null = null;
  timeListLoading: boolean = true;
  groupedTimes: GroupedTimesModel[] = [];
  customerTimeDifference: string | null = '+0';

  constructor(
    private router: Router,
    private appoinmentService: AppoinmentService,
    private customerService: CustomerService
  ) {
    const currentDate = new Date();
    this.today = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate(),
    };
    this.selectedDate = `${String(this.today.day).padStart(2, '0')}-${String(
      this.today.month
    ).padStart(2, '0')}-${this.today.year}`;
  }

  ngOnInit(): void {
    this.getAppoinmentTypes();
    this.getResources();
    this.getCustomerTimeDifference();
  }

  getAppoinmentTypes() {
    this.appoinmentService.appoinmentTypes().subscribe({
      next: (res) => {
        this.appoinmentTypes = res;
        if (this.appoinmentTypes.length) {
          this.selectedAppoinmentType = this.appoinmentTypes[0].id;
        }
      },
      error: () => {
        this.displayToast(
          true,
          'Er is een fout opgetreden bij het ophalen van de afspraaktypen.',
          'error'
        );
      },
    });
  }

  getResources() {
    this.customerService.resources().subscribe({
      next: (res) => {
        this.resources = res;
        if (this.resources.length) {
          this.selectedResource = this.resources[0].id;
          this.dateSelect(this.today);
        }
      },
      error: () => {
        this.displayToast(
          true,
          'Er is een fout opgetreden bij het ophalen van bronnen.',
          'error'
        );
      },
    });
  }

  isDisabled(date: { year: number; month: number; day: number }): boolean {
    const today = new Date();

    const currentMonthStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    const currentMonthEnd = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    const nextMonthStart = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      1
    );
    const nextMonthEnd = new Date(today.getFullYear(), today.getMonth() + 2, 0);

    const currentDate = new Date(date.year, date.month - 1, date.day);

    return !(
      (currentDate >= currentMonthStart && currentDate <= currentMonthEnd) ||
      (currentDate >= nextMonthStart && currentDate <= nextMonthEnd)
    );
  }

  dateSelect(date: { year: number; month: number; day: number }) {
    const formattedDate = `${date.year}-${String(date.month).padStart(
      2,
      '0'
    )}-${String(date.day).padStart(2, '0')}`;
    this.selectedDate = formattedDate;
    this.displaySelectedDate = `${String(date.day).padStart(2, '0')}-${String(
      date.month
    ).padStart(2, '0')}-${date.year}`;
    this.getAppointmentTimeList(formattedDate);
  }

  getAppointmentTimeList(date: string) {
    this.timeListLoading = true;
    if (this.selectedResource) {
      this.appoinmentService
        .appointmentTimeList(date, this.selectedResource || '')
        .pipe(
          finalize(() => {
            this.timeListLoading = false;
          })
        )
        .subscribe({
          next: (res) => {
            this.availableTimes = res;
            this.groupedTimes = this.groupByTimeSlot();
          },
          error: () => {
            this.displayToast(
              true,
              'Er is een fout opgetreden bij het ophalen van de lijst met afspraaktijden.',
              'error'
            );
          },
        });
    }
  }

  navigateToPreparation(time: string): void {
    if (!this.selectedAppoinmentType || !this.selectedResource) {
      this.showToast = false;
      setTimeout(() => {
        this.displayToast(
          true,
          'Afspraaktype en resourceselectie zijn vereist',
          'warning'
        );
      }, 0);
      return;
    }

    const selectedAppoinmentType = this.appoinmentTypes.find(
      (type) => type.id === this.selectedAppoinmentType
    );

    const selectedResource = this.resources.find(
      (resource) => resource.id === this.selectedResource
    );

    this.router.navigate(['/preparation'], {
      state: {
        appoinmentType: selectedAppoinmentType,
        resource: selectedResource,
        selectedDate: this.selectedDate,
        selectedTime: time,
        timeDifference: this.customerTimeDifference,
      },
    });
  }

  displayToast(show: boolean, message: string, type: string | any) {
    this.showToast = show;
    this.toastMessage = message;
    this.toastType = type;
  }

  groupByHourRange(): GroupedTimesModel[] {
    const groupedTimes: GroupedTimesModel[] = [];
    for (let i = 0; i < this.availableTimes.length; i += 2) {
      const startHour = this.availableTimes[i].hour;
      const endHour = this.availableTimes[i + 1]
        ? this.availableTimes[i + 1].hour
        : '';

      const range = `${startHour}-${endHour}`;

      groupedTimes.push({
        range,
        availableTimes: this.availableTimes.slice(i, i + 2),
      });
    }

    return groupedTimes;
  }

  private getTimeSlot(hour: string): string {
    const start = hour.split(':')[0]; // Get the hour part
    const end = (parseInt(start) + 1) % 24; // Get the next hour for the range
    return `${start}:00-${end.toString().padStart(2, '0')}:00`; // Format as HH:00-HH:00
  }

  private groupByTimeSlot(): any[] {
    const result = [];
    let currentSlot: string = '';
    let currentSlotData: AvailableTimesModel[] = [];

    this.availableTimes.forEach((item) => {
      const slot = this.getTimeSlot(item.hour);
      if (slot !== currentSlot) {
        if (currentSlot) {
          result.push({ range: currentSlot, availableTimes: currentSlotData });
        }
        currentSlot = slot;
        currentSlotData = [item];
      } else {
        currentSlotData.push(item);
      }
    });

    if (currentSlot) {
      result.push({ range: currentSlot, availableTimes: currentSlotData });
    }

    return result;
  }

  private getCustomerTimeDifference(): void {
    this.customerService.getCustomer().subscribe({
      next: (res) => {
        this.customerTimeDifference = res.timeDifference;
        console.log('time difference:', this.customerTimeDifference);
      },
      error: () => {
        this.displayToast(
          true,
          'Er is een fout opgetreden bij het ophalen van bronnen.',
          'error'
        );
      },
    });
  }

  getMinDate(): NgbDateStruct {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const result: NgbDateStruct = {
      year,
      month,
      day,
    };
    return result;
  }

  hasAvailableTime(times: AvailableTimesModel[] | undefined) {
    if(this.isCalendarDateBiggerThenToday()) return true;
    if (!times?.length) return false;
    let result = false;
    for (const item of [...times].reverse()) {
      if (item.isAvailable && !this.isDisabledTimeButton(item.hour)) {
        result = true;
        break;
      }
    }
    return result;
  }

  isDisabledTimeButton(time: string | undefined) {
    try {
      if(this.isCalendarDateBiggerThenToday()) return false;
      if (!time) return true;
      let buttonTime = time;
      if (buttonTime.length === 5) buttonTime += ':00';
      const localeTime = new Date().toLocaleTimeString(undefined, {
        hourCycle: 'h24',
      });
      const buttonTimeParts = buttonTime.split(':').map(Number);
      const localeTimeParts = localeTime.split(':').map(Number);
      const buttonTimeSeconds =
        buttonTimeParts[0] * 3600 +
        buttonTimeParts[1] * 60 +
        buttonTimeParts[2];
      const localeTimeSeconds =
        localeTimeParts[0] * 3600 +
        localeTimeParts[1] * 60 +
        localeTimeParts[2];
      const result: boolean = buttonTimeSeconds < localeTimeSeconds;
      return result;
    } catch (e) {
      return true;
    }
  }

  isCalendarDateBiggerThenToday() {
    const calendarDate = new Date(
      this.today.year,
      this.today.month - 1,
      this.today.day
    );
    const currentDate = new Date();
    if (calendarDate > currentDate) return true;
    return false;
  }
}
