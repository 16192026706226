<div class="create-appoinment p-4">
  <div class="container">
    <div class="card shadow-sm p-3">
      <!-- Make your appointment here -->
      <h5>Maak hier uw afspraak</h5>
      <!-- Appointment type -->
      <div class="row align-items-center mt-3">
        <div class="col-md-2 mb-0">Afspraaktype*</div>
        <div class="col-md-3">
          <select
            [(ngModel)]="selectedAppoinmentType"
            class="form-select"
            aria-label="Appoinment Type"
          >
            <option [ngValue]="null" disabled selected>
              Selecteer een type
            </option>
            <option
              *ngFor="let appoinmentType of appoinmentTypes"
              [value]="appoinmentType.id"
            >
              {{ appoinmentType.name }}
            </option>
          </select>
        </div>
      </div>
      <!-- Resource -->
      <div class="row align-items-center mt-3">
        <div class="col-md-2 mb-0">Hulpbron*</div>
        <div class="col-md-3">
          <select
            [(ngModel)]="selectedResource"
            class="form-select"
            aria-label="Resource"
          >
            <option [ngValue]="null" disabled selected>
              Selecteer een bron
            </option>
            <option *ngFor="let resource of resources" [value]="resource.id">
              {{ resource.name }}
            </option>
          </select>
        </div>
      </div>
      <!-- Choose a date and time -->
      <div class="row calendar-content">
        <div class="col-md-6">
          <h5 class="fw-normal">Kies een datum en tijd</h5>
          <ngb-datepicker
            [(ngModel)]="today"
            [markDisabled]="isDisabled"
            (dateSelect)="dateSelect($event)"
            [minDate]="getMinDate()"
          ></ngb-datepicker>
        </div>
        <div class="col-md-6">
          <h6 class="fw-normal">
            Geschikte tijden voor {{ displaySelectedDate }}
          </h6>
          <div
            *ngIf="groupedTimes?.length; else noAvailableTimes"
            class="accordion"
            id="accordionExample"
          >
            <div
              *ngFor="let groupedTime of groupedTimes; let i = index"
              class="accordion-item"
            >
              <h2 class="accordion-header cursor-pointer">
                <button
                  class="accordion-button text-dark button-color collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#collapse' + i"
                  aria-expanded="false"
                  [attr.aria-controls]="'collapse' + i"
                  [disabled]="!hasAvailableTime(groupedTime?.availableTimes)"
                >
                  <div class="d-flex align-items-center gap-4">
                    <div>
                      <strong>{{ groupedTime?.range || "-" }}</strong>
                    </div>
                  </div>
                </button>
              </h2>
              <div
                [attr.id]="'collapse' + i"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="d-flex flex-wrap gap-2 align-items-center">
                    <div
                      *ngFor="
                        let time of groupedTime.availableTimes;
                        let isLast = last;
                        let i = index
                      "
                      (click)="navigateToPreparation(time.hour)"
                      class="text-center badge time-badge py-2 cursor-pointer text-bg-primary"
                      [ngClass]="
                        !time.isAvailable || isDisabledTimeButton(time.hour)
                          ? 'disable'
                          : 'null'
                      "
                    >
                      <a class="cursor-pointer text-white text-decoration-none">
                        {{ time.hour }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noAvailableTimes>
            <p>Er is geen beschikbare tijd beschikbaar.</p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Toast Message -->
<app-toast
  [message]="toastMessage"
  [type]="toastType"
  [showToast]="showToast"
></app-toast>
