<div class="create-appoinment p-4">
  <div class="container">
    <div class="card shadow-sm p-3">
      <div class="text-center">
        <i class="fa-regular fa-circle-check fa-5x"></i>
        <h2 class="my-3">Bedankt!</h2>
        <h5 class="fw-normal">Uw inzending is succesvol verzonden.</h5>
      </div>
    </div>
  </div>
</div>
