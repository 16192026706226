<header class="bg-white border-bottom sticky-top">
  <div class="container d-flex align-items-center justify-content-between px-xl-0 px-4">
    <a href="https://www.ivpg.nl/">
      <img src="https://www.ivpg.nl/wp-content/uploads/2020/12/logo_ivpg.jpg" alt="IVPG Logo" class="navbar-logo">
    </a>
    <nav class="navbar navbar-expand-lg">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" href="https://www.ivpg.nl/">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.ivpg.nl/huidkanker/over-huidkanker/landing-huidkanker/">Start Onderzoek</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.ivpg.nl/huidkanker/">Over Huidkanker</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.ivpg.nl/blog-2/">Blog</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Over IVPG
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="https://www.ivpg.nl/contact/">Contact</a></li>
              <li><a class="dropdown-item" href="https://www.ivpg.nl/over-ons/klachtenformulier/">Klachtenformulier</a></li>
            </ul>
          </li>
        </ul> -->
      </div>
    </nav>

    <!-- Search Form -->
    <!-- <form class="d-none d-lg-flex search-form" role="search" action="https://www.ivpg.nl/">
      <input type="search" class="form-control me-2" placeholder="Zoeken..." name="s" aria-label="Search" required>
      <button class="search-button" type="submit" aria-label="Zoeken"><i class="bi bi-search"></i></button>
    </form> -->
  </div>
</header>

<!-- Mobile Search Form -->
<!-- <div class="container d-lg-none mt-2">
  <form class="search-form" role="search" action="https://www.ivpg.nl/">
    <input type="search" class="form-control" placeholder="Zoeken..." name="s" aria-label="Search" required>
    <button class="search-button" type="submit" aria-label="Zoeken"><i class="bi bi-search"></i></button>
  </form>
</div> -->