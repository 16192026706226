import { Routes } from '@angular/router';
import { CreateAppoinmentComponent } from './pages/create-appoinment/create-appoinment.component';
import { PreparationComponent } from './pages/preparation/preparation.component';
import { ConsentInformationComponent } from './pages/consent-information/consent-information.component';
import { SuccessPageComponent } from './pages/success-page/success-page.component';
import { AppointmentApprovePageComponent } from './pages/appointment-approve-page/appointment-approve-page.component';

export const routes: Routes = [
  { path: '', redirectTo: '/create-appoinment', pathMatch: 'full' },
  {
    path: 'create-appoinment',
    component: CreateAppoinmentComponent,
  },
  {
    path: 'preparation',
    component: PreparationComponent,
  },
  {
    path: 'consent-information',
    component: ConsentInformationComponent,
  },
  {
    path: 'success',
    component: SuccessPageComponent,
  },
  {
    path: 'approve-appointment/:appointmentCaseId',
    component: AppointmentApprovePageComponent,
  },
];
