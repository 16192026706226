import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function lettersOnlyValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = /^[a-zA-ZçÇğĞıİöÖşŞüÜ\s]*$/.test(control.value);
    return valid ? null : { lettersOnly: true };
  };
}

export function sixDigitNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = /^\d{6}$/.test(control.value);
    return valid ? null : { sixDigitNumber: { value: control.value } };
  };
}

export function numberOnlyValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = /^[0-9]+$/.test(control.value);
    return valid ? null : { numberOnly: { value: control.value } };
  };
}
