import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppoinmentService {
  constructor(private http: HttpClient) {}

  appoinmentTypes(): Observable<any> {
    let url = `${environment.apiLocations.appoinment}/Appointment`;

    return this.http.get<any>(url);
  }

  sendAppoinment(form: any): Observable<any> {
    let url = `${environment.apiLocations.appoinment}/Appointment`;

    return this.http.post<any>(url, form);
  }

  appointmentTimeList(time: string, branchId: string): Observable<any> {
    let url = `${environment.apiLocations.appoinment}/Appointment/GetFreeAppointmentTimeList?selectedDate=${time}&customerCode=IVPG&customerBranchId=${branchId}`;

    return this.http.get<any>(url);
  }

  approveAppointment(apoointmentCaseId: string): Observable<any> {
    let url = `${environment.apiLocations.patient}/AppointmentCase/${apoointmentCaseId}`;

    return this.http.patch<any>(url,{});
  }
}
