import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  resources(): Observable<any> {
    let url = `${environment.apiLocations.customer}/Customer/GetBranchesByCustomerCode/IVPG`;

    return this.http.get<any>(url);
  }

  getCustomer(): Observable<any> {
    let url = `${environment.apiLocations.customer}/Customer/GetCustomer/IVPG`;

    return this.http.get<any>(url);

  }
}
