import { Component } from '@angular/core';

@Component({
  selector: 'app-consent-information',
  standalone: true,
  imports: [],
  templateUrl: './consent-information.component.html',
  styleUrl: './consent-information.component.scss'
})
export class ConsentInformationComponent {

}
