import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent {
  @Input() message: string = '';
  @Input() type: 'success' | 'error' | 'warning' = 'success';
  @Input() showToast: boolean = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showToast'] && this.showToast) {
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    }
  }
}
