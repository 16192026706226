import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  lettersOnlyValidator,
  numberOnlyValidator,
} from '../../validators/validators';
import { Router, RouterModule } from '@angular/router';
import { AppoinmentService } from '../../services/appoinment/appoinment.service';
import { ToastComponent } from '../../shared/toast/toast.component';
import {
  AppoinmentTypeModel,
  ResourceModel,
} from '../../models/appointment/appoinment.model';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

@Component({
  selector: 'app-preparation',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ToastComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  templateUrl: './preparation.component.html',
  styleUrl: './preparation.component.scss',
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: 'siteKey',
        hl: 'nl', // Set the language to Dutch or any other language code
      } as RecaptchaSettings,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PreparationComponent {
  @ViewChild(ToastComponent) toastComponent!: ToastComponent;
  showToast: boolean = false;
  toastMessage: string = '';
  toastType: 'success' | 'error' | 'warning' = 'success';
  maxDate: string;
  preparationForm: FormGroup;
  date: string = '';
  time: string = '';
  formattedDateTime: string = '';
  appoinmentType: AppoinmentTypeModel = {
    id: '',
    name: '',
  };
  resource: ResourceModel = {
    id: '',
    name: '',
    customerId: '',
  };
  selectedDate: string = '';
  selectedTime: string = '';
  submitLoading: boolean = false;
  captchaResolved: boolean = false;
  captchaToken: string | null = null;
  siteKey = environment.siteKey;
  timeDifference: string = '+0';
  language: string = 'nl';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private appoinmentService: AppoinmentService
  ) {
    const today = new Date();
    this.maxDate = today.toISOString().split('T')[0];
    this.preparationForm = this.fb.group({
      gender: [null],
      name: ['', [Validators.required, lettersOnlyValidator()]],
      lastName: ['', [Validators.required, lettersOnlyValidator()]],
      email: ['', [Validators.required, Validators.email]],
      birthDate: ['', Validators.required],
      mobilePhone: [
        '',
        [Validators.required, Validators.pattern('^\\+?[0-9]{7,15}$')],
      ],
      address: [''],
      houseNumber: ['', [Validators.required]],
      postCode: ['', [Validators.required]],
      place: [''],
      comment: [''],
      consent: [false, Validators.requiredTrue],
    });
  }

  ngOnInit(): void {
    this.appoinmentType = history.state.appoinmentType;
    this.resource = history.state.resource;
    this.selectedDate = history.state.selectedDate;
    this.selectedTime = history.state.selectedTime;
    this.timeDifference = history.state.timeDifference;
    this.formattedDateTime = this.formatDateTime(
      this.selectedDate,
      this.selectedTime
    );
    this.loadRecaptcha();
  }

  formatDateTime(date: string, time: string): string {
    const [day, month, year] = date.split('-').map(Number);
    const [hours, minutes] = time.split(':').map(Number);

    const dateTime = new Date(year, month - 1, day, hours, minutes);

    const dayName = new Intl.DateTimeFormat('nl-NL', {
      weekday: 'short',
    }).format(dateTime);
    const monthName = new Intl.DateTimeFormat('nl-NL', {
      month: 'long',
    }).format(dateTime);

    const timeString = new Intl.DateTimeFormat('nl-NL', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(dateTime);

    return `${dayName} ${day} ${monthName} om ${timeString}`;
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.preparationForm.get(controlName);
    return control ? control.invalid && control.touched : false;
  }

  onCaptchaResolved(token: string | null): void {
    this.captchaToken = token;
    this.captchaResolved = !!token;
  }

  async savePreparation(e: Event) {
    e.preventDefault();
    if (this.preparationForm.valid) {
      if (this.captchaResolved) {
        this.submitLoading = true;
        const formData = {
          selectedDateTime: this.formatSelectedDateTime(
            this.selectedDate,
            this.selectedTime
          ),
          customerBranchId: this.resource.id,
          customerBranchName: this.resource.name,
          appointmentTypeId: this.appoinmentType.id,
          customerCode: 'IVPG',
          timeDifference: this.timeDifference,
          ...this.preparationForm.value,
        };
        delete formData.consent;

        this.appoinmentService.sendAppoinment(formData).subscribe({
          next: async () => {
            this.preparationForm.disable();
            this.showToast = false;
            setTimeout(() => {
              this.displayToast(
                true,
                'Het proces is succesvol afgerond',
                'success'
              );
            }, 0);
            setTimeout(() => {
              this.router.navigate(['/success']);
            }, 1000);
          },
          error: () => {
            this.displayToast(
              true,
              'Er is een fout opgetreden bij het verzenden van het formulier',
              'error'
            );
          },
        });
      } else {
        this.showToast = false;
        setTimeout(() => {
          this.displayToast(true, 'Recaptcha-selectie is vereist!', 'warning');
        }, 0);
      }
    } else {
      this.preparationForm.markAllAsTouched();
    }
  }

  formatSelectedDateTime(date: string, time: string): string {
    const dateTimeString = `${date}T${time}:00`;
    const dateTime = new Date(dateTimeString);

    const timezoneOffset = dateTime.getTimezoneOffset() * 60000; //Convert minutes to milliseconds time difference
    const localISOTime = new Date(dateTime.getTime() - timezoneOffset)
      .toISOString()
      .slice(0, -1); //Remove the trailing "Z" from the ISO string, which indicates UTC time

    return localISOTime;
  }

  displayToast(show: boolean, message: string, type: string | any) {
    this.showToast = show;
    this.toastMessage = message;
    this.toastType = type;
  }

  loadRecaptcha() {
    if (document.getElementById('recaptcha-script')) {
      this.checkAndRenderCaptcha();
    } else {
      const script = document.createElement('script');
      script.id = 'recaptcha-script';
      script.src = `https://www.google.com/recaptcha/api.js?hl=${this.language}`;
      script.async = true;
      script.defer = true;

      script.onload = () => this.checkAndRenderCaptcha();

      document.body.appendChild(script);
    }
  }

  checkAndRenderCaptcha() {
    if (typeof window.grecaptcha !== 'undefined') {
      window.grecaptcha.render('recaptcha-container', {
        sitekey: this.siteKey,
      });
    } else {
      setTimeout(() => this.checkAndRenderCaptcha(), 100);
    }
  }
}
