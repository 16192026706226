<div
  class="toast-container position-fixed top-0 end-0 p-3"
  style="z-index: 9999"
>
  <div
    *ngIf="showToast"
    class="toast align-items-center border-0 show"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div
      [ngClass]="[
        'toast-header',
        type === 'success'
          ? 'bg-success'
          : type === 'error'
          ? 'bg-danger'
          : 'bg-warning',
        'text-white'
      ]"
    >
      <strong class="me-auto fs-5">{{
        type === "success"
          ? "Succes"
          : type === "error"
          ? "Fout"
          : "Waarschuwing"
      }}</strong>
      <button
        type="button"
        class="btn-close bg-white cursor-pointer"
        (click)="showToast = false"
        aria-label="Close"
      ></button>
    </div>
    <div class="toast-body fs-6 py-4">{{ message }}</div>
  </div>
</div>
