import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from '../../shared/toast/toast.component';
import { AppoinmentService } from '../../services/appoinment/appoinment.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-appointment-approve-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './appointment-approve-page.component.html',
  styleUrl: './appointment-approve-page.component.scss',
})
export class AppointmentApprovePageComponent {
  @ViewChild(ToastComponent) toastComponent!: ToastComponent;
  appointmentCaseId: string = '';
  showToast: boolean = false;
  toastMessage: string = '';
  toastType: 'success' | 'error' | 'warning' = 'success';
  isSuccess: boolean = false;
  isOpenedPage: boolean = true;
  isFail: boolean = false;

  constructor(
    private appoinmentService: AppoinmentService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.readAppointmentCaseIdFromRoute();
  }

  readAppointmentCaseIdFromRoute(): void {
    this.appointmentCaseId = this.route.snapshot.paramMap.get('appointmentCaseId') || '';
  }

  approveAppointment(): void {
    this.appoinmentService
      .approveAppointment(this.appointmentCaseId)
      .subscribe({
        next: (res) => {
          this.isOpenedPage = false;
          this.isSuccess = res;
          this.displayToast(
            true,
            'Afspraak succesvol bevestigd.',
            'success'
          );
        },
        error: () => {
          this.isOpenedPage = false;
          this.isFail = true;
          this.displayToast(
            true,
            'Er is een fout opgetreden bij het bevestigen van de afspraak.',
            'error'
          );
        }
      });
  }

  displayToast(show: boolean, message: string, type: string | any) {
    this.showToast = show;
    this.toastMessage = message;
    this.toastType = type;
  }

  showErrorMessage() {
    this.isOpenedPage = false;
    this.isFail = true;
  }
}
