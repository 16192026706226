<div class="preparation p-4">
  <div class="container">
    <div class="card shadow-sm p-3">
      <div class="card-header bg-white">
        <div class="d-flex align-items-center gap-4">
          <div class="calendar-icon">
            <i class="fa-regular fa-calendar-days fa-3x"></i>
          </div>
          <div class="card-header-right">
            <p class="mb-0">{{ appoinmentType.name || "" }}</p>
            <p class="mb-0">{{ resource.name || "" }}</p>
            <p class="mb-0">{{ formattedDateTime || "" }}</p>
            <a href="#" class="text-decoration-none">Een andere tijd kiezen</a>
          </div>
        </div>
      </div>
      <div class="card-body">
        <h5 class="fw-normal">Jouw gegevens</h5>
        <form
          [formGroup]="preparationForm"
          (ngSubmit)="savePreparation($event)"
          id="contactForm"
          data-sb-form-api-token="API_TOKEN"
          class="mb-12"
        >
          <div class="row g-2 mb-3 mt-3">
            <!-- Aanhef(Salutation) -->
            <div class="row mb-3">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-md-2">
                    <p class="mb-0">Aanhef</p>
                  </div>
                  <div class="col-md-5">
                    <div class="form-floating">
                      <div class="d-flex align-items-center gap-4">
                        <div class="form-check">
                          <input
                            formControlName="gender"
                            class="form-check-input"
                            type="radio"
                            id="mrOption"
                            value="2"
                          />
                          <label class="form-check-label" for="mrOption"
                            >De heer</label
                          >
                        </div>
                        <div class="form-check">
                          <input
                            formControlName="gender"
                            class="form-check-input"
                            type="radio"
                            id="msOption"
                            value="3"
                          />
                          <label class="form-check-label" for="msOption"
                            >Mevrouw</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Vornaam(First Name) -->
            <div class="row mb-3">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-md-2">
                    <p class="mb-0">Vornaam*</p>
                  </div>
                  <div class="col-md-5">
                    <div class="form-floating">
                      <input
                        formControlName="name"
                        class="form-control form-control-sm"
                        id="inputName"
                        type="text"
                        placeholder="John Doe"
                      />
                      <label for="inputName">Vornaam</label>
                      <div *ngIf="isControlInvalid('name')" class="text-danger">
                        <div
                          *ngIf="preparationForm.get('name')?.errors?.['required']"
                        >
                          Voornaam is verplicht
                        </div>
                        <div
                          *ngIf="!preparationForm.get('name')?.errors?.['required'] && preparationForm.get('name')?.errors?.['lettersOnly']"
                        >
                          De voornaam mag alleen uit letters bestaan.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Achternaam(Last Name) -->
            <div class="row mb-3">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-md-2">
                    <p class="mb-0">Achternaam*</p>
                  </div>
                  <div class="col-md-5">
                    <div class="form-floating">
                      <input
                        formControlName="lastName"
                        class="form-control form-control-sm"
                        id="inputLastName"
                        type="text"
                        placeholder="John Doe"
                      />
                      <label for="inputLastName">Achternaam</label>
                      <div
                        *ngIf="isControlInvalid('lastName')"
                        class="text-danger"
                      >
                        <div
                          *ngIf="preparationForm.get('lastName')?.errors?.['required']"
                        >
                          Achternaam is verplicht
                        </div>
                        <div
                          *ngIf="!preparationForm.get('lastName')?.errors?.['required'] && preparationForm.get('lastName')?.errors?.['lettersOnly']"
                        >
                          De achternaam mag alleen uit letters bestaan.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- E-mailadres(Email) -->
            <div class="row mb-3">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-md-2">
                    <p class="mb-0">E-mailadres*</p>
                  </div>
                  <div class="col-md-5">
                    <div class="form-floating">
                      <input
                        formControlName="email"
                        class="form-control form-control-sm"
                        id="inputEmail"
                        type="text"
                        placeholder="john@test.com"
                      />
                      <label for="inputEmail">E-mailadres</label>
                      <div
                        *ngIf="isControlInvalid('email')"
                        class="text-danger"
                      >
                        <div
                          *ngIf="preparationForm.get('email')?.errors?.['required']"
                        >
                          E-mailadres is verplicht
                        </div>
                        <div
                          *ngIf="preparationForm.get('email')?.errors?.['email']"
                        >
                          E-mailadres is niet geldig
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class="mt-0">
              Via dit e-mailadres houden we je op de hoogte over de afspraak
            </p>
            <!-- Geboortedatum(Date of Birth) -->
            <div class="row mb-3">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-md-2">
                    <p class="mb-0">Geboortedatum*</p>
                  </div>
                  <div class="col-md-5">
                    <div class="form-floating">
                      <input
                        formControlName="birthDate"
                        type="date"
                        class="form-control"
                        id="inputDob"
                        placeholder="Date of Birth"
                        [max]="maxDate"
                      />
                      <label for="inputDob">Geboortedatum</label>
                      <div
                        *ngIf="isControlInvalid('birthDate')"
                        class="text-danger"
                      >
                        <div
                          *ngIf="preparationForm.get('birthDate')?.errors?.['required']"
                        >
                          Geboortedatum is verplicht
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Mobiel*(Mobile Phone) -->
            <div class="row mb-3">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-md-2">
                    <p class="mb-0">Mobiel*</p>
                  </div>
                  <div class="col-md-5">
                    <div class="form-floating">
                      <input
                        formControlName="mobilePhone"
                        class="form-control form-control-sm"
                        id="inputMobilePhone"
                        type="text"
                        placeholder="Mobiel"
                      />
                      <label for="inputMobilePhone">Mobiel</label>
                      <div
                        *ngIf="isControlInvalid('mobilePhone')"
                        class="text-danger"
                      >
                        <div
                          *ngIf="preparationForm.get('mobilePhone')?.errors?.['required']"
                        >
                          Mobiel is verplicht
                        </div>
                        <div
                          *ngIf="preparationForm.get('mobilePhone')?.errors?.['pattern']"
                        >
                          Mobiel is niet geldig
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class="mt-0">
              Belangrijk voor als wij contact op willen nemen over de afspraak
            </p>
            <p class="mt-0">
              Uw telefoonnummer en emailadres zullen gebruikt worden voor het
              beveiligd mailen van de uitslagen
            </p>
            <!-- Adres(Address) -->
            <div class="row mb-3">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-md-2">
                    <p class="mb-0">Adres</p>
                  </div>
                  <div class="col-md-5">
                    <div class="form-floating">
                      <input
                        formControlName="address"
                        class="form-control form-control-sm"
                        id="inputAddress"
                        type="text"
                        placeholder="Adres"
                      />
                      <label for="inputAddress">Adres</label>
                      <div
                        *ngIf="isControlInvalid('address')"
                        class="text-danger"
                      >
                        <div
                          *ngIf="preparationForm.get('address')?.errors?.['required']"
                        >
                          Adres is verplicht
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Huisnummer*(House Number) -->
            <div class="row mb-3">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-md-2">
                    <p class="mb-0">Huisnummer*</p>
                  </div>
                  <div class="col-md-5">
                    <div class="form-floating">
                      <input
                        formControlName="houseNumber"
                        class="form-control form-control-sm"
                        id="inputHouseNumber"
                        type="text"
                        placeholder="Heisnummer"
                      />
                      <label for="inputHouseNumber">Huisnummer</label>
                      <div
                        *ngIf="isControlInvalid('houseNumber')"
                        class="text-danger"
                      >
                        <div
                          *ngIf="preparationForm.get('houseNumber')?.errors?.['required']"
                        >
                          Huisnummer is verplicht
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Postcode*(Post Code) -->
            <div class="row mb-3">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-md-2">
                    <p class="mb-0">Postcode*</p>
                  </div>
                  <div class="col-md-5">
                    <div class="form-floating">
                      <input
                        formControlName="postCode"
                        class="form-control form-control-sm"
                        id="inputPostCode"
                        type="text"
                        placeholder="Postcode"
                      />
                      <label for="inputPostCode">Postcode</label>
                      <div
                        *ngIf="isControlInvalid('postCode')"
                        class="text-danger"
                      >
                        <div
                          *ngIf="preparationForm.get('postCode')?.errors?.['required']"
                        >
                          Postcode is verplicht
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Plaats(Place) -->
            <div class="row mb-3">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-md-2">
                    <p class="mb-0">Plaats</p>
                  </div>
                  <div class="col-md-5">
                    <div class="form-floating">
                      <input
                        formControlName="place"
                        class="form-control form-control-sm"
                        id="inputPlace"
                        type="text"
                        placeholder="Plaats"
                      />
                      <label for="inputPlace">Plaats</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h5 class="fw-normal mb-3">Voeg een opmerking toe</h5>
            <!-- Opmerking(Comment) -->
            <div class="row mb-3">
              <div class="col-md-12">
                <div class="row align-items-start">
                  <div class="col-md-2">
                    <p class="mb-0">Opmerking</p>
                  </div>
                  <div class="col-md-5">
                    <div class="form-floating">
                      <textarea
                        formControlName="comment"
                        class="form-control"
                        id="comment"
                        placeholder="opmerking"
                        style="height: 120px"
                      ></textarea>
                      <label for="inputPostCode">Opmerking</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class="mt-0">
              Indien je een opmerking wil toevoegen kun je dit in bovenstaand
              veld doen.
            </p>
            <!-- Consent Information -->
            <div class="form-check">
              <input
                formControlName="consent"
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Ik ga akkoord met het verwerken van mijn persoonsgegevens en
                verklaar hierbij de
                <a
                  [routerLink]="['/consent-information']"
                  target="_blank"
                  class="text-decoration-none"
                >
                  Inform Consent informatie
                </a>
                heb gelezen en heb begrepen*
              </label>
              <div *ngIf="isControlInvalid('consent')" class="text-danger">
                <div
                  *ngIf="preparationForm.get('consent')?.errors?.['required']"
                >
                  Het veld is verplicht
                </div>
              </div>
            </div>
          </div>

          <!-- I am not a robot -->
          <div class="captcha" id="recaptcha-container">
            <re-captcha
              [siteKey]="siteKey"
              (resolved)="onCaptchaResolved($event)"
            >
            </re-captcha>
          </div>

          <!-- Save Preparation -->
          <div class="row mt-6">
            <div class="col">
              <!-- Submit Appoinment -->
              <button
                *ngIf="!submitLoading; else submitting"
                type="submit"
                class="btn btn-primary cursor-pointer ms-6"
              >
                Afspraak bevestigen
              </button>
              <ng-template #submitting>
                <button class="btn btn-primary" type="button" disabled>
                  <span
                    class="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                  <span role="status" class="ms-1">Verzenden...</span>
                </button>
              </ng-template>
            </div>
          </div>
        </form>

        <!-- Back to Appoinment -->
        <div class="back-to-appoinment">
          <a
            [routerLink]="['/create-appoinment/']"
            class="text-decoration-none"
          >
            <i class="fa-solid fa-angles-left"></i>
            Terug</a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Toast Message -->
<app-toast
  [message]="toastMessage"
  [type]="toastType"
  [showToast]="showToast"
></app-toast>
