<div *ngIf="isOpenedPage" class="create-appoinment p-4">
  <div class="container">
    <div class="card shadow-sm p-3">
      <div class="text-center">
        <h5 class="fw-normal">Bevestigt u uw afspraak?</h5>
      </div>
      <div class="button-group">
        <button type="submit" (click)="approveAppointment()" class="btn btn-primary cursor-pointer ms-6">Ja</button>
        <button type="submit" (click)="showErrorMessage()" class="btn btn-danger cursor-pointer ms-6">Nee</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isSuccess && !isOpenedPage" class="create-appoinment p-4">
  <div class="container">
    <div class="card shadow-sm p-3">
      <div class="text-center">
        <i class="fa-regular fa-circle-check fa-5x"></i>
        <h2 class="my-3">Bedankt!</h2>
        <h5 class="fw-normal">Uw afspraak is succesvol bevestigd.</h5>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isFail && !isOpenedPage" class="create-appoinment p-4">
  <div class="container">
    <div class="card shadow-sm p-3">
      <div class="text-center">
        <i class="fa-regular fa-solid fa-exclamation fa-5x"></i>
        <h2 class="my-3">Waarschuwing!</h2>
        <h5 class="fw-normal">Uw afspraak kon niet worden bevestigd!</h5>
      </div>
    </div>
  </div>
</div>
