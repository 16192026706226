<div class="consent-informations p-4">
  <div class="container">
    <div class="card shadow-sm p-3">
      <div class="card-header bg-white">
        <h5 class="fw-normal">Consent informatie</h5>
      </div>
      <div class="card-body">
        Het onderzoek:
        <br /><br />
        In overleg met onze assistente bepaalt u welke plekjes u onderzocht wilt
        hebben.
        <br /><br />
        Wij maken gebruik van een geavanceerd systeem dat goedaardige en
        kwaadaardige letsels van elkaar kan onderscheiden met een hoge medische
        validiteit. De resultaten worden beoordeeld door één van onze
        dermatologen, die gespecialiseerd zijn in tele-dermatologie.
        <br /><br />
        Bij de volgende zorgverzekeraars worden de beoordelingen door de
        dermatoloog volledig vergoed:
        <br /><br />
        Aevitae | Aevitae ASR | Aevitae VGZ | Achmea | Anderzorg | Aon | A.S.R.
        | AZVZ | Besured | | Bewuzt | CZ | De Friesland | Delta Lloyd | Ditzo |
        DSW | FBTO | Hema | Interpolis | In Twente | IZA | IZZ | Jaaah | Just |
        Menzis | National Academic | Nationale Nederlanden | OHRA | ONVZ | PMA |
        PNO | PNO zorg | Pro Life | Promovendum | Salland| Stad Holland |
        Studenten Goed Verzekerd | SZVK | UMC | United Consumers | Univé | VGZ |
        VGZ voor de Zorg | VVAA | ZEKUR | ZieZo van Zilveren Kruis | Zilveren
        Kruis | Zorg en Zekerheid | ZorgDirect |
        <br /><br />
        Ook de eerste scan wordt door deze zorgverzekeraars vergoed. U ontvangt
        hiervoor geen rekening en het gaat niet van uw eigen risico af. U bent
        vrij om meerdere scans te laten maken. Per extra scan brengen wij voor
        het maken van de scan in rekening.
        <br /><br />
        Bij afwezigheid ontvangt u een no show factuur. Hiervoor gelden de
        volgende uitzonderingen:
        <br /><br />
        Bij een afmelding van 48 uur voor de afspraak:
        <br /><br />
        Kosten
        <br /><br />
        Bij een afmelding van 24 uur voor de afspraak per onderzoek.
        <br /><br />
        Bij een afmelding van op de dag van de afspraak of zonder afmelding
        wordt per onderzoek in rekening gebracht.
      </div>
    </div>
  </div>
</div>
